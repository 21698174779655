import type { CurrencyCode } from 'domains/money/types'
import { useControlled } from 'kitchen/hooks/use-controlled'
import { usePayeeReferenceRifm } from 'kitchen/hooks/use-payee-reference-rifm'
import { Input, type InputProps } from '../input'

export interface PayeeReferenceInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange' | 'maxLength'> {
  currency: CurrencyCode
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
}

export const PayeeReferenceInput = ({
  defaultValue = '',
  value: controlledValue,
  onChange,
  currency,
  ...rest
}: PayeeReferenceInputProps) => {
  const [value, setValue] = useControlled<string>({
    defaultValue,
    value: controlledValue,
    onChange,
  })

  const rifm = usePayeeReferenceRifm({
    value,
    onChange: setValue,
    currency,
  })

  return <Input {...rifm} {...rest} />
}
